import { ApplicationRef, ComponentRef, createComponent, EmbeddedViewRef, EnvironmentInjector, inject, Injectable, Injector, Type } from '@angular/core';
import { Subject } from 'rxjs';
import { IgOverlayAlertContentComponent } from './ig-overlay-alert-content/ig-overlay-alert-content/ig-overlay-alert-content.component';
import { OverlayAlertConfig } from './overlay-alert-config';
import { OverlayAlertInjector } from './overlay-alert-injector';
import { OverlayAlertRef } from './overlay-alert-ref';
import { Button, ButtonWithChildComponentType, OverlayAlertComponent, OverlayAlertSeverity } from './overlay-alert.component';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { IgOverlayDeleteConfirmationComponent } from './ig-overlay-delete-confirmation/ig-overlay-delete-confirmation.component';
import { IgOverlayAlertPromptComponent } from './ig-overlay-alert-prompt/ig-overlay-alert-prompt.component';
import { InlineAlertSeverity } from '../../elements/inline-alert/inline-alert.component';
import { UiTranslateService } from '../../helper/lang/ui-translate.service';

export interface CustomDialogResult<T> {
  componentRef: OverlayAlertComponent;
  childComponentRef?: T;
  alertRef?: OverlayAlertRef;
}
export enum CheckboxVisibility {
  AUTO = 'auto',
  SHOW = 'show',
  HIDE = 'hide'
}

/**
 * if your custom component, used in OverlayAlerService.custom extends this class, the config.data will be typed!
 * see storybook for more details
 */
export class OverlayAlertCustomComponent<T = Record<string, any>> {
  __overlayAlertConfigData?: T;
  protected readonly dialogConfig: OverlayAlertConfig<typeof this> = inject(OverlayAlertConfig);
}

export interface ExtendedConfirmationOptions {
  /**
   * the mode of confirmation (sets default texts and colors)
   */
  confirmMode?: 'delete' | 'remove' | 'other';
  /**
   * A headline for the confirmation dialog
   */
  headline?: string;
  /**
   * an optional icon class
   */
  iconClass?: string;
  /**
   * optional message (e.g consequence of deletion 'you will permanently loose xyz') may contain markdown
   */
  message?: string;
  /**
    * optional string that must be typed into textfield to make deletion happening (e.g. the name of the object). Undefined means there is no input field
    */
  typeToConfirm?: string;
  /**
    * on critical operation (that cannot be undone) set to true to display an additional warning
    */
  cannotBeUndone?: boolean;
  /**
    * custom warning text for critical operation
    */
  cannotBeUndoneText?: string;
  /**
   * optional width for the dialog
   */
  width?: string;
  /**
    * If subobjects should be displayed (that can or will be also deleted) provide one or more subobject lists, use the object type as key
    */
  subobjectLists?: Record<
    string,
    {
      /**
      *  provide an array with the (translated) column headlines for the object-list
      */
      listHeadlines: string[];
      /**
      *  provide an array with the the list headline widths
      */
      listHeadlineWidths?: string[];
      /**
       * optional tooltip (via hover on info-icon) for a headline
       */
      listHeadlineTooltips?: (string | undefined)[];
      /**
      * intro text displayed above the list (e.g. "your server is connected with objects. Uncheck the objects you want to keep")
      */
      intro?: string;
      /**
      * note text displayed under the list
      */
      note?: string;
      /**
      * optional ordering of the subobject-list (will be ordered by key otherwise)
      */
      order?: number;
      /**
      * checkbox visibility: show - forces the checkbox to show; hide - forces the checkbox to hide; auto - lets the canAnythingBeDeselected decide
      */
      checkboxVisibility?: CheckboxVisibility;
      /**
      * provide a list with objects, use a unique object identifier as key
      */
      objects?: Record<
        string,
        {
          /**
          * the objects checkbox is initially selected or not
          */
          selected: boolean;
          /**
          * the checkbox is disabled
          */
          disabled?: boolean;
          /**
          * an array of object data in the same order as the `deleteSubObjectsHeadlines
          */
          dataColumns: (string | number | boolean)[];
          /**
          * optional define a data column to order the list by
          */
          orderByDataColumn?: number;
        }
      >;
    }
  >;
  /**
  * Hide content area to remove padding between headline and buttons
  */
  hideContentArea?: boolean;
  /**
   * include an ig-inline-alert component to the dialog
   * @param severity The severity of the inline alert (same as for ig-inline-alert)
   * @param message The message to display. May contain markdown
   */
  includeInlineAlert?: { severity: InlineAlertSeverity | string; message: string };

  /**
   * optional base z-index given to the overlay alert
   */
  baseZIndex?: number;
}

export interface DeleteConfirmationOptions extends Omit<ExtendedConfirmationOptions, 'confirmMode' | 'iconClass' | 'headline'> {
  /**
   * the (translated) type of the object to be deleted (e.g. 'Server', 'Storage'....)
   */
  objectType: string;
  /**
   * the objects name
   */
  objectName: string;
  /**
   * optional custom headline (normally you should only pass object type and object name)
   */
  customHeadline?: string;
  /**
   * optional custom headline icon class
   */
  customHeadlineIconCls?: string;

}

@Injectable({
  providedIn: 'root'
})
export class OverlayAlertService {
  constructor(private readonly environmentInjector: EnvironmentInjector, private appRef: ApplicationRef, private injector: Injector, private translate: UiTranslateService) { }

  /**
   * displays an alert with the headline and message given, returns a subject which can optionally be subscribed for feedback
   */
  alert(headline: string | undefined, message: string, allowMultipleAlerts?: boolean): Subject<void> {
    const subj = new Subject<void>();

    this.appendComponentToBody({
      severity: OverlayAlertSeverity.warning,
      headline,
      message,
      allowMultipleAlerts: allowMultipleAlerts === undefined ? true : allowMultipleAlerts,
      buttons: [
        {
          label: 'OK',
          mode: 'primary',
          keyShortcut: 'Escape',
          icon: '',
          callback: (button, alert) => {
            subj.next();
            subj.complete();
            alert.close();
          }
        }
      ]
    });
    return subj;
  }

  /**
   * displays a confirmation with the headline and message given, returns a subject which can optionally be subscribed for feedback
   * You can provide custom buttons with custom callbacks
   * or do not provide any buttons which will lead to default 'yes' and 'no buttons
   * and then subscribe to the returned subject to get boolean if confirmed or not
   */
  confirm(
    headline: string,
    message: string,
    buttons?: Button[],
    iconCls?: string,
    allowMultipleAlerts?: boolean,
    showCloseButton?: boolean,
    includeInlineAlert?: { severity: string; message: string, lightStyle?: boolean }
  ): Subject<boolean> {
    const subj = new Subject<boolean>();

    this.appendComponentToBody({
      severity: OverlayAlertSeverity.question,
      iconCls,
      headline,
      message,
      buttons,
      showCloseButton,
      allowMultipleAlerts: allowMultipleAlerts === undefined ? true : allowMultipleAlerts,
      callback: confirmed => {
        subj.next(!!confirmed);
        subj.complete();
      },
      includeInlineAlert
    });

    return subj;
  }

  prompt(
    headline: string,
    inputLabel: string,
    submitBtnText: string,
    subheadline?: string,
    initialValue?: string,
    inputPattern?: RegExp,
    iconCls?: string,
    includeInlineAlert?: { severity: string; message: string }
  ): Subject<string> {
    const subj = new Subject<string>();

    const buttons: Button[] = [
      {
        label: this.translate.instant('cancel'),
        keyShortcut: 'Escape',
        mode: 'secondary',
        callback: (button, alert) => {
          alert.close();
        }
      },
      {
        label: submitBtnText,
        mode: 'primary',
        callback: (button, alert, childComponentRef) => {
          alert.close();

          subj.next(childComponentRef?.instance.userInput);
          subj.complete();
        }
      }
    ];

    this.appendComponentToBody(
      {
        severity: OverlayAlertSeverity.question,
        iconCls,
        headline,
        buttons,
        subheadline,
        allowMultipleAlerts: true,
        catchFocus: false,
        data: {
          inputLabel,
          inputPattern,
          initialValue
        },
        includeInlineAlert
      },
      IgOverlayAlertPromptComponent
    );

    return subj;
  }

  /**
   * displays a special delete confirmation with the headline and message given, returns a subject which can optionally be subscribed for feedback
   * You can provide custom buttons with custom callbacks
   * or do not provide any buttons which will lead to default 'yes' and 'no buttons
   * and then subscribe to the returned subject to get boolean if confirmed or not
   *
   */
  confirmDelete(options: DeleteConfirmationOptions, buttons?: Button[]): Subject<{ confirmed: boolean | Button; subobjectsSelected: Record<string, string[]> }> {
    if (buttons === undefined) {
      // set some default buttons
      buttons = [
        {
          label: this.translate.instant('delete_confirm_keep'),
          keyShortcut: 'Escape',
          mode: 'secondary',
          callback: (button, alert) => {
            alert.callback.emit(false);
            alert.close();
          }
        },
        {
          label: this.translate.instant('delete_confirm_delete', { objectType: options.objectType }),
          mode: 'danger',
          callback: (button, alert) => {
            alert.callback.emit(true);
            alert.close();
          }
        }
      ];
    }

    return this.confirmExtended({
      ...options,
      confirmMode: 'delete',
      iconClass: _.get(options, 'customHeadlineIconCls', 'icon-a033_delete'),
      headline: _.get(options, 'customHeadline', this.translate.instant('delete_confirm_headline', { objectType: options.objectType, objectName: options.objectName })),
    }, buttons);
  }

  /**
   * displays a special remove confirmation with the headline and message given, returns a subject which can optionally be subscribed for feedback
   * You can provide custom buttons with custom callbacks
   * or do not provide any buttons which will lead to default 'yes' and 'no buttons
   * and then subscribe to the returned subject to get boolean if confirmed or not
   *
   */
  confirmRemove(options: DeleteConfirmationOptions, buttons?: Button[]): Subject<{ confirmed: boolean | Button; subobjectsSelected: Record<string, string[]> }> {
    if (buttons === undefined) {
      // set some default buttons
      buttons = [
        {
          label: this.translate.instant('remove_confirm_keep'),
          keyShortcut: 'Escape',
          mode: 'secondary',
          callback: (button, alert) => {
            alert.callback.emit(false);
            alert.close();
          }
        },
        {
          label: this.translate.instant('remove_confirm_delete', { objectType: options.objectType }),
          mode: 'danger',
          callback: (button, alert) => {
            alert.callback.emit(true);
            alert.close();
          }
        }
      ];
    }

    return this.confirmExtended({
      ...options,
      confirmMode: 'remove',
      iconClass: _.get(options, 'customHeadlineIconCls', 'icon-a059_warning'),
      headline: _.get(options, 'customHeadline', this.translate.instant('remove_confirm_headline', { objectType: options.objectType, objectName: options.objectName })),
    }, buttons);
  }

  /**
   * displays an extended confirmation dialog with more options as the normal `confirm` (can be considered as confirm 2.0) returns a subject which can optionally be subscribed for feedback
   * You can provide custom buttons with custom callbacks
   * or do not provide any buttons which will lead to default 'yes' and 'no buttons
   * and then subscribe to the returned subject to get boolean if confirmed or not
   */
  confirmExtended(options: ExtendedConfirmationOptions, buttons?: Button[]): Subject<{ confirmed: boolean | Button; subobjectsSelected: Record<string, string[]> }> {
    if (options.confirmMode === undefined) {
      options.confirmMode = 'other';
    }

    const subj = new Subject<{ confirmed: boolean | Button; subobjectsSelected: Record<string, string[]> }>();

    const alert = this.appendComponentToBody(
      {
        severity: OverlayAlertSeverity.question,
        iconCls: options.iconClass,
        headline: options.headline,
        message: options.message,
        buttons,
        styleClass: 'ig-inline-alert-extended-confirm' + (options.confirmMode === 'delete' || options.confirmMode === 'remove' ? ' ig-inline-alert-delete-confirm' : ''),
        allowMultipleAlerts: true,
        isDeleteContainer: true,
        hideContentArea: options.hideContentArea,
        includeInlineAlert: options.includeInlineAlert,
        baseZIndex: options.baseZIndex,
        width: options.width,
        catchFocus: !options.typeToConfirm,
        callback: confirmed => {
          const subobjectsSelected: Record<string, string[]> = {};
          if (_.get(alert, 'childComponentRef.options.subobjectLists')) {
            _.forEach(alert.childComponentRef.options.subobjectLists, (list, listKey) => {
              subobjectsSelected[listKey] = [];
              if (_.get(list, 'objects')) {
                _.forEach(list.objects, (object, objectKey) => {
                  if (object.selected) {
                    subobjectsSelected[listKey].push(objectKey);
                  }
                });
              }
            });
          }

          subj.next({
            confirmed,
            subobjectsSelected
          });
          subj.complete();
        },
        data: options
      },
      IgOverlayDeleteConfirmationComponent
    );

    return subj;
  }


  /**
   * create an overlay-alert with a custom angular component and custom buttons
   */

  custom<T>(
    headlineOrConfig: string | (Omit<OverlayAlertConfig<T>, 'severity' | 'message' | 'callback' | 'isDeleteContainer' | 'buttons'> & {
      /**
       * @deprecated Providing buttons from outside is deprecated (provide it from inside the custom component, see https://ingrid-storybook.eu-central-1.gos3.io/?path=/docs/components-overlay-alert--overlay-alert#within-your-custom-component)
       */
      buttons?: ButtonWithChildComponentType<T>[];
    }),
    component: Type<T>,
    /**
     * @deprecated Providing buttons from outside is deprecated (provide it from inside the custom component, see https://ingrid-storybook.eu-central-1.gos3.io/?path=/docs/components-overlay-alert--overlay-alert#within-your-custom-component), also you should provide an `OverlayAlertConfig` object with that property as a first parameter
     */
    buttons?: ButtonWithChildComponentType<T>[],
    /**
     * @deprecated you should provide an `OverlayAlertConfig` object with that property  as a first parameter
     */
    data?: (T & OverlayAlertCustomComponent)['__overlayAlertConfigData'],
    /**
     * @deprecated you should provide an `OverlayAlertConfig` object with that property  as a first parameter
     */
    iconCls?: string,
    /**
     * @deprecated you should provide an `OverlayAlertConfig` object with that property  as a first parameter
     */
    subheadline?: string,
    /**
     * @deprecated you should provide an `OverlayAlertConfig` object with that property  as a first parameter
     */
    width?: string,
    /**
     * @deprecated you should provide an `OverlayAlertConfig` object with that property  as a first parameter
     */
    fullwidthContent?: boolean,
    /**
     * @deprecated you should provide an `OverlayAlertConfig` object with that property  as a first parameter
     */
    allowMultipleAlerts?: boolean,
    /**
     * @deprecated you should provide an `OverlayAlertConfig` object with that property  as a first parameter
     */
    showCloseButton?: boolean,
    /**
     * @deprecated you should provide an `OverlayAlertConfig` object with that property  as a first parameter
     */
    showBackButton?: boolean,
    /**
     * @deprecated you should provide an `OverlayAlertConfig` object with that property  as a first parameter
     */
    environmentInjector?: EnvironmentInjector,
    /**
     * @deprecated you should provide an `OverlayAlertConfig` object with that property  as a first parameter
     */
    advancedAlignmentMode?: boolean,
    /**
     * @deprecated you should provide an `OverlayAlertConfig` object with that property  as a first parameter
     */
    mandatoryLabel?: boolean
  ): CustomDialogResult<T> {

    if (typeof headlineOrConfig === 'object' && headlineOrConfig !== null) {
      return this.appendComponentToBody(
        headlineOrConfig,
        component
      );

    } else {
      return this.appendComponentToBody(
        {
          iconCls,
          data,
          headline: headlineOrConfig as string,
          subheadline,
          allowMultipleAlerts: allowMultipleAlerts === undefined ? true : allowMultipleAlerts,
          width,
          fullwidthContent,
          buttons,
          showCloseButton,
          showBackButton,
          environmentInjector,
          advancedAlignmentMode,
          mandatoryLabel
        },
        component
      );

    }
  }

  /**
   * Creates instance of the overlay alert component (and optional custom child component) and appends to the body
   */
  private appendComponentToBody<T>(config: OverlayAlertConfig<T>, childComponent?: Type<T>): CustomDialogResult<T> {
    // stores child nodes (for custom content)
    const projectableNodes: any[] = [];
    let alertRef: OverlayAlertRef | undefined;
    let childComponentRef: ComponentRef<T> | undefined;

    // append child (=advancedMode)
    if (childComponent) {
      // a map, containing injectables that we set, and should not be resolved by normal injector
      const injectMap = new WeakMap();
      config.isOverlayAlert = true;
      injectMap.set(OverlayAlertConfig, config);
      // create an instance of OverlayAlertRef and pass it as custom injectable to custom component so we can get the alerts instance there
      alertRef = new OverlayAlertRef();
      injectMap.set(OverlayAlertRef, alertRef);

      // childComponentRef = this.componentFactoryResolver.resolveComponentFactory(childComponent).create(new OverlayAlertInjector(this.injector, injectMap));

      childComponentRef = createComponent(childComponent, {
        elementInjector: new OverlayAlertInjector(config.environmentInjector || this.environmentInjector, injectMap),
        environmentInjector: config.environmentInjector || this.environmentInjector
      });

      const contentComponentRef = createComponent(IgOverlayAlertContentComponent, {
        elementInjector: this.injector,
        environmentInjector: config.environmentInjector || this.environmentInjector,
        projectableNodes: [[childComponentRef.location.nativeElement]]
      });


      projectableNodes.push([contentComponentRef.location.nativeElement]);
    }

    // Create a component reference from the overlay-alert component
    const componentRef = createComponent(OverlayAlertComponent, {
      elementInjector: this.injector,
      environmentInjector: config.environmentInjector || this.environmentInjector,
      projectableNodes
    });

    if (childComponent) {
      if (childComponentRef) {
        // bind some lifecycle hook from the overlayalert component to the child component
        componentRef.instance.onDestroy$.subscribe(() => {
          childComponentRef!.destroy();
        });

        componentRef.instance.doCheck$.subscribe(() => {
          childComponentRef!.changeDetectorRef.detectChanges();
        });

        if ((childComponentRef.instance as unknown as any).ngOnInit) {
          // componentRef.instance.onInit$.subscribe(() => {
          //   console.log('oninit dispatch');
          //   (childComponentRef.instance as unknown as any).ngOnInit();
          // });
        }
      }

      // subscribe to close event of the OverlayAlertRef (for custom content)
      alertRef?.onClose.pipe(takeUntil(componentRef.instance.onDestroy$)).subscribe(() => {
        componentRef.instance.close();
      });

      // subscribe to setFocus event of the OverlayAlertRef (for custom content)
      alertRef?.onSetFocus.pipe(takeUntil(componentRef.instance.onDestroy$)).subscribe(() => {
        componentRef.instance.setFocus();
      });

      // subscribe to setLoading event of the OverlayAlertRef (for custom content)
      alertRef?.onSetLoading.pipe(takeUntil(componentRef.instance.onDestroy$)).subscribe(loading => {
        componentRef.instance.loading = loading;
      });

      // subscribe to update button event of the OverlayAlertRef (for custom content)
      alertRef?.onUpdateButton.pipe(takeUntil(componentRef.instance.onDestroy$)).subscribe(buttonUpdate => {
        if (_.isArray(componentRef.instance.buttons) && componentRef.instance.buttons[buttonUpdate.buttonIndex]) {
          (componentRef.instance.buttons as Button[])[buttonUpdate.buttonIndex] = _.assign(componentRef.instance.buttons[buttonUpdate.buttonIndex], buttonUpdate.button);
        }
      });

      if (alertRef?.onBeforeClose) {
        componentRef.instance.onBeforeClose = alertRef?.onBeforeClose;
      }
    }

    // set alert attributes
    if (childComponent) {
      if (config.isDeleteContainer === true) {
        componentRef.instance.hasCustomContentComponent = true;
      } else {
        componentRef.instance.advancedMode = true;
      }
    }

    if (config.severity) {
      componentRef.instance.severity = config.severity;
    }

    if (config.message) {
      componentRef.instance.message = config.message;
    }



    if (config.headline) {
      componentRef.instance.headline = config.headline;
    }

    if (config.subheadline) {
      componentRef.instance.subheadline = config.subheadline;
    }

    if (config.iconCls) {
      componentRef.instance.iconCls = config.iconCls;
    }

    if (config.buttons) {
      componentRef.instance.buttons = config.buttons;
    }

    if (config.width) {
      componentRef.instance.width = config.width;
    }

    if (config.fullwidthContent) {
      componentRef.instance.fullwidthContent = config.fullwidthContent;
    }

    if (config.allowMultipleAlerts) {
      componentRef.instance.allowMultipleAlerts = config.allowMultipleAlerts;
    }

    if (config.includeInlineAlert) {
      componentRef.instance.includeInlineAlert = config.includeInlineAlert;
    }

    if (config.callback) {
      const sub = componentRef.instance.callback.subscribe(res => {
        config.callback!(res);
        sub.unsubscribe();
      });
    }

    if (config.showCloseButton !== undefined) {
      componentRef.instance.showCloseButton = config.showCloseButton;
    }

    if (config.showBackButton) {
      componentRef.instance.showBackButton = config.showBackButton;
    }

    if (config.advancedAlignmentMode) {
      componentRef.instance.advancedAlignmentMode = config.advancedAlignmentMode;
    }

    if (config.mandatoryLabel) {
      componentRef.instance.mandatoryLabel = config.mandatoryLabel;
    }

    if (config.styleClass) {
      componentRef.instance.styleClass = config.styleClass;
    }

    if (config.hideContentArea) {
      componentRef.instance.hideContentArea = config.hideContentArea;
    }

    if (config.closeOnBackground !== undefined) {
      componentRef.instance.closeOnBackground = config.closeOnBackground;
    }

    if (config.baseZIndex !== undefined) {
      componentRef.instance.baseZIndex = config.baseZIndex;
    }

    if (config.isModal !== undefined) {
      componentRef.instance.isModal = config.isModal;
    }

    componentRef.instance.catchFocus = true;
    if (config.catchFocus === false) {
      componentRef.instance.catchFocus = config.catchFocus;
    }

    if (childComponentRef) {
      componentRef.instance.childComponentRef = childComponentRef;
    }

    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);

    // Get DOM element from component
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    // Append DOM element to the body
    document.body.appendChild(domElem);

    componentRef.instance.show();

    return {
      componentRef: componentRef.instance,
      childComponentRef: childComponentRef ? childComponentRef.instance : undefined,
      alertRef
    };
  }
}
